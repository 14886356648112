import { NgModule } from '@angular/core';
import { MaterialModule } from '@angular/material';
import { AppComponent } from "./app.component";
import { routing } from './app.routing';
import { TerminalService } from './terminal/terminal.service';
import { Http, RequestOptions, XHRBackend } from '@angular/http';
import { HttpInterceptor } from './http-interceptor/http-interceptor.service';
import { Router } from '@angular/router';

import * as AppBundle from './app.bundle';

@NgModule({
  imports: [
    routing,
    AppBundle.LoginModule,
    AppBundle.HeaderModule,
    AppBundle.InitialModule,
    AppBundle.TerminalModule,
    AppBundle.BlockedTerminalModule,
    MaterialModule.forRoot()
  ],
  declarations: [
    AppComponent
  ],
  providers: [
    {
      provide: Http,
      useFactory: (backend: XHRBackend, options: RequestOptions, router: Router ) => {
        return new HttpInterceptor(backend, options, router);
      },
      deps: [XHRBackend, RequestOptions, Router]
    }
  ], 
  bootstrap: [AppComponent]
})
export class AppModule {
}